import { Avatar, Box, Button, Flex, Text } from '@mantine/core';
import { useState } from 'react';

import { Input } from '~/components/molecules';
import { copyToClipboard } from '~/utils';

import ConnectorIconComponent from '~/assets/icons/connector.svg';
import CopyIconComponent from '~/assets/icons/copy.svg';
import NoResultsIllustration from '~/assets/images/modals/invite-teammates-modal/no-results.svg';

import * as Styles from './InviteTeammatesModal.styled';

const InviteTeammatesModal = ({ innerProps }) => {
  const {
    invitationUrlPath,
    collaborators,
    listOfTeamMembersLabel = 'All Team Members',
    instructionLabel = 'Copy and share the link with your colleagues to invite them to Kello.',
  } = innerProps;
  const [isInviteLinkCopied, setIsInviteLinkCopied] = useState(false);

  const invitationUrl = location.origin + invitationUrlPath;

  const handleCopyLinkBtnClick = async () => {
    copyToClipboard(invitationUrl);
    setIsInviteLinkCopied(true);
    setTimeout(() => setIsInviteLinkCopied(false), 3000);
  };

  let collaboratorsNode;

  if (collaborators.length) {
    collaboratorsNode = (
      <Styles.TeamMembersContainer cols={2} mt="24px">
        {collaborators.map((collaborator) => (
          <Flex align="center" key={collaborator.id}>
            <Avatar src={collaborator.picture} alt="User Avatar" color="#fff" bg="#614fd9" radius="xl">
              {collaborator.name[0].toUpperCase()}
            </Avatar>
            <Box ml="12px">
              <Text c="#77767B" fz="14px" fw="650">
                {collaborator.name}
              </Text>
              <Text c="#A4A3A7" fz="11px" fw="450" mt="4px">
                {collaborator.email}
              </Text>
            </Box>
          </Flex>
        ))}
      </Styles.TeamMembersContainer>
    );
  } else {
    collaboratorsNode = (
      <Flex justify="center" align="center" mt="24px" style={{ flexDirection: 'column' }}>
        <Styles.NoResultsIllustration component={NoResultsIllustration} />
        <Text mt="32px" ta="center" c="#77767B" fz="14px" fw="650">
          You’re the first one here
        </Text>
        <Text mt="8px" ta="center" fz="11px" fw="450" c="#A4A3A7">
          {instructionLabel}
        </Text>
      </Flex>
    );
  }

  return (
    <Box>
      <Flex align="flex-end">
        <Input
          styles={{
            root: {
              flex: 1,
            },
            input: {
              border: '0px',
              background: '#F8F8F8',
              paddingLeft: '44px',
              color: '#A4A3A7',
              fontWeight: '450',
            },
            section: {
              paddingLeft: '14px',
            },
            label: {
              color: '#77767B',
            },
          }}
          label="Share this link to invite"
          leftSection={<Styles.ConnectorIcon component={ConnectorIconComponent} />}
          value={invitationUrl}
          readOnly
        />
        <Button
          leftSection={isInviteLinkCopied ? null : <Styles.CopyIcon component={CopyIconComponent} />}
          styles={{
            root: {
              borderRadius: '8px',
              background: '#5A47D7',
              border: '1px solid #7B6CDF',
              height: '48px',
            },
          }}
          ml="8px"
          onClick={() => {
            if (!isInviteLinkCopied) {
              handleCopyLinkBtnClick();
            }
          }}
        >
          {isInviteLinkCopied ? 'Link Copied!' : 'Copy Link'}
        </Button>
      </Flex>
      <Text mt="32px" fz="14px" c="#77767B" fw="650">
        {listOfTeamMembersLabel}
      </Text>
      {collaboratorsNode}
    </Box>
  );
};

export default InviteTeammatesModal;
