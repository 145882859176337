import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Global, ThemeProvider } from '@emotion/react';
import { Flex, Text, useMantineTheme } from '@mantine/core';
// import * as Sentry from '@sentry/react';
// eslint-disable-next-line no-unused-vars
import { BrowserHistory } from 'history';

import { useIsMobileMedia } from '~/hooks';

import faviconIcoFile from '~/assets/icons/favicon/favicon.ico';

/**
 *
 * @param {{history: BrowserHistory}} props
 */
function AppRoot({ children, history }) {
  const theme = useMantineTheme();
  const isTabletOrMobileMedia = useIsMobileMedia();
  // const userQuery = useGetUserQuery();
  // const user = userQuery.data?.user;

  const [isAppUpdateAvailable, setIsAppUpdateAvailable] = useState(false);

  // useEffect(() => {
  //   if (user) {
  //     Sentry.setUser({ id: user.id, email: user.email, name: user.name });
  //   } else {
  //     Sentry.configureScope((scope) => scope.setUser(null));
  //   }
  // }, [user]);

  // Listen for `UPDATE_AVAILABLE` broadcast event
  useEffect(() => {
    if ('BroadcastChannel' in window) {
      const channel = new BroadcastChannel('sw-broadcast-update');
      channel.onmessage = (event) => {
        if (event.data.type === 'UPDATE_AVAILABLE') {
          setIsAppUpdateAvailable(true);
        }
      };
    }
  }, []);

  // Do a full page reload during navigation if an app update is available
  useEffect(() => {
    const unregister = history.listen(({ location: { pathname, search, hash } }) => {
      if (isAppUpdateAvailable) {
        const url = `${pathname}${search}${hash}`;
        window.location.href = url;
      }
    });
    return unregister;
  }, [isAppUpdateAvailable]);

  let nodeToRender;

  if (isTabletOrMobileMedia) {
    nodeToRender = (
      <Flex justify="center" align="center" style={{ flexDirection: 'column' }} py="64px">
        <Text fz="16px" fw="650">
          We currently don't support Mobile.
        </Text>
        <Text fz="14px" fw="450" ta="center" c="#77767B">
          Please use a laptop to have an amazing experience of Kello.
        </Text>
      </Flex>
    );
  } else {
    nodeToRender = children;
  }

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Kello</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content="#000000" />
        <link rel="alternate icon" href={faviconIcoFile} />
      </Helmet>
      <Global />
      {nodeToRender}
    </ThemeProvider>
  );
}

export default AppRoot;
