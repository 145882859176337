import { modals } from '@mantine/modals';

import { MODAL_IDS } from '~/constants';

export const openSearchHistoryModal = (args) => {
  return modals.openContextModal({
    size: '1116px',
    styles: {
      content: {
        borderRadius: '16px',
        background: '#fff',
        border: '1px solid #efeff0',
        boxShadow:
          '0px 101px 28px 0px rgba(0, 0, 0, 0), 0px 65px 26px 0px rgba(0, 0, 0, 0), 0px 36px 22px 0px rgba(0, 0, 0, 0.02), 0px 16px 16px 0px rgba(0, 0, 0, 0.03), 0px 4px 9px 0px rgba(0, 0, 0, 0.03)',
      },
      header: {
        padding: '24px',
        borderBottom: '1px solid #efeff0',
      },
      title: {
        color: '#16151C',
        fontSize: '18px',
        fontWeight: '650',
      },
      body: {
        padding: '0px',
      },
    },
    ...args,
  });
};

export const openMessageModal = (args) => {
  return modals.openContextModal({
    size: '416px',
    styles: {
      content: {
        borderRadius: '16px',
        background: '#fff',
        border: '1px solid #efeff0',
      },
      header: {
        position: 'absolute',
        width: '100%',
        background: 'transparent',
      },
      body: { padding: '40px' },
    },
    ...args,
  });
};

export const openInviteTeammatesModal = (args) => {
  return modals.openContextModal({
    modal: MODAL_IDS.INVITE_TEAM_MATES_MODAL,
    size: '600px',
    styles: {
      content: {
        borderRadius: '16px',
        background: '#fff',
        border: '1px solid #efeff0',
        boxShadow:
          '0px 101px 28px 0px rgba(0, 0, 0, 0), 0px 65px 26px 0px rgba(0, 0, 0, 0), 0px 36px 22px 0px rgba(0, 0, 0, 0.02), 0px 16px 16px 0px rgba(0, 0, 0, 0.03), 0px 4px 9px 0px rgba(0, 0, 0, 0.03)',
      },
      header: {
        padding: '24px',
        borderBottom: '1px solid #efeff0',
      },
      title: {
        color: '#16151C',
        fontSize: '18px',
        fontWeight: '650',
      },
      body: {
        padding: '24px',
      },
    },
    ...args,
  });
};

export const openCollectionActionModal = (args) => {
  return modals.openContextModal({
    modal: MODAL_IDS.COLLECTION_ACTION_MODAL,
    size: '600px',
    styles: {
      content: {
        borderRadius: '16px',
        background: '#fff',
        border: '1px solid #efeff0',
        boxShadow:
          '0px 101px 28px 0px rgba(0, 0, 0, 0), 0px 65px 26px 0px rgba(0, 0, 0, 0), 0px 36px 22px 0px rgba(0, 0, 0, 0.02), 0px 16px 16px 0px rgba(0, 0, 0, 0.03), 0px 4px 9px 0px rgba(0, 0, 0, 0.03)',
      },
      header: {
        padding: '24px',
        borderBottom: '1px solid #efeff0',
      },
      title: {
        color: '#16151C',
        fontSize: '18px',
        fontWeight: '650',
      },
      body: {
        padding: '0px',
      },
    },
    ...args,
  });
};

export const openConfirmationModal = (args) => {
  return modals.openContextModal({
    modal: MODAL_IDS.CONFIRMATION_MODAL,
    size: '600px',
    styles: {
      content: {
        borderRadius: '16px',
        background: '#fff',
        border: '1px solid #efeff0',
        boxShadow:
          '0px 101px 28px 0px rgba(0, 0, 0, 0), 0px 65px 26px 0px rgba(0, 0, 0, 0), 0px 36px 22px 0px rgba(0, 0, 0, 0.02), 0px 16px 16px 0px rgba(0, 0, 0, 0.03), 0px 4px 9px 0px rgba(0, 0, 0, 0.03)',
      },
      header: {
        padding: '24px',
        borderBottom: '1px solid #efeff0',
      },
      title: {
        color: '#16151C',
        fontSize: '18px',
        fontWeight: '650',
      },
      body: {
        padding: '0px',
      },
    },
    ...args,
  });
};
