import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Avatar, Box, Flex, Text } from '@mantine/core';
import { NavLink } from 'react-router-dom';

import { useAppContext } from '~/contexts/app';

import KelloLogo from '~/assets/kello-logo.svg';

const Header = forwardRef(({ trackEvent, ...rest }, ref) => {
  const { user } = useAppContext();

  const handleLogoClick = () => {
    trackEvent('attempted_to_navigate');
  };

  let userDetailsNode;

  if (user) {
    userDetailsNode = (
      <UserDetailsContainer>
        <Avatar
          src={user.picture}
          alt="User Avatar"
          color="#fff"
          bg="#614fd9"
          size="24px"
          styles={{
            root: {
              cursor: 'pointer',
            },
          }}
        >
          {user.name[0].toUpperCase()}
        </Avatar>
        <Text c="#77767B" fz="11px" fw="450" ml="8px">
          {user.name}
        </Text>
      </UserDetailsContainer>
    );
  }

  return (
    <Root component="header" ref={ref} {...rest}>
      <Content>
        <BrandDetailsContainer component={NavLink} to="/app" display="inline-flex" onClick={handleLogoClick}>
          <LogoWrapper>
            <KelloLogo />
          </LogoWrapper>
          <Text ml="4px" fz="24px" fw="650" lh="28px" c="#18161E">
            kello
          </Text>
        </BrandDetailsContainer>
        {userDetailsNode}
      </Content>
    </Root>
  );
});

export default Header;

export const Root = styled(Box)`
  height: 76px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: #ffffff;
`;

const Content = styled(Flex)`
  margin: auto;
  max-width: 1272px; // 1272 = 1224 content + 24 padding on left and right
  padding: 24px;
  align-items: center;
  justify-content: space-between;
`;

export const BrandDetailsContainer = styled(Flex)`
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
  transform: all 300ms ease-in-out;
`;

export const LogoWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
  width: 24px;
  height: 24px;

  > svg {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
  }
`;

export const UserDetailsContainer = styled(Flex)`
  background: #f8f8f8;
  align-items: center;
  border-radius: 32px;
  padding: 8px;
  padding-right: 12px;
`;
